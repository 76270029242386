import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingUsers)?_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSkeletonLoader,{attrs:{"transition":"fade","type":"table"}})],1)],1)],1):_c(VContainer,[_c(VRow,[_c(VCol,[_c('h2',{staticClass:"text-center text-h3"},[_vm._v("All Referrals")])])],1),(_vm.users.length === 0)?_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4","offset-md":"4"}},[_c(VCard,[_c(VCardText,{staticClass:"text-center"},[_c('h1',{staticClass:"headline"},[_vm._v(" There are no referrals... yet ")])])],1)],1)],1):_vm._e(),(_vm.users.length)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c(VTextField,{attrs:{"append-icon":_vm.searchIcon,"clearable":"","placeholder":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"search":_vm.query,"items":_vm.referralsData,"single-expand":true,"show-expand":"","item-key":"id","sort-by":['amountEarned'],"sort-desc":[true],"custom-filter":_vm.$constants.VUETIFY.CUSTOM_SEARCH,"items-per-page":_vm.footerProps.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:items":function($event){_vm.referralsData=$event}},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("dateTime")(props.item.updatedAt))+" ")]}},{key:"item.name",fn:function(props){return [_c('div',{domProps:{"innerHTML":_vm._f("htmlDisplayName")(props.item.name)}})]}},{key:"item.amountEarned",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("currency")(props.item.amountEarned))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VSubheader,{staticClass:"mt-4 mb-2"},[_c('h6',{staticClass:"text-h6"},[_vm._v(" Timeline ")])]),_c(VTimeline,{staticClass:"pr-4",attrs:{"dense":""}},_vm._l((item.orders),function(order){return _c(VTimelineItem,{key:order.id,attrs:{"small":""}},[_c('span',{staticClass:"text--secondary font-weight-bold"},[_vm._v("We")]),_vm._v(" transferred "+_vm._s(_vm._f("currency")(order.amountEarned .amount))+" to "+_vm._s(order.paymentPhoneNumber || item.paymentPhoneNumber)+" on "),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm._f("longDateTime")(order.createdAt))+" ")]),_vm._v(" as bonus for order with ID: "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(order.orderId))])])}),1)],1)],1)],1)]}}],null,false,51975943)})],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }